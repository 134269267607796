<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          fab
          small
          style="margin: -4px 0;"
          @click="dialog = true"
        >
          <v-icon color="red">mdi-close</v-icon>
        </v-btn>
      </template>

      <span>Удалить</span>
    </v-tooltip>

    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title>
          Удаление маршрута из договора
        </v-card-title>

        <v-divider />

        <v-card-text class="pt-4">
          Вы уверены, что хотите удалить маршрут № {{ item.routeCode }} 
          из списка для закрытия маршрутов в договоре?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-row dense>
            <v-col>
              <v-btn
                outlined
                color="red"
                @click="dialog = false"
              >
                Отмена
              </v-btn>
            </v-col>

            <v-col class="text-right">
              <v-btn
                color="primary"
                @click="removeRoute"
              >
                Удалить
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: "DeleteRouteItem",
    props: {
      item: {
        type: Object,
        required: true,
      }
    },
    data: () => ({
      dialog: false,
    }),
    methods: {
      removeRoute() {
        this.dialog = false;

        this.$emit('onRemove', this.item);
      }
    }
  }
</script>

